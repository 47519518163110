
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ResponseDialog extends Vue {
    dialog = false

    @Prop({ default: '' })
    title!: string

    toggle(): void {
        this.dialog = !this.dialog
    }
}
